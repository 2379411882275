
  import Vue from 'vue';
  import { Map, Marker } from 'leaflet';
  import { oc as Optional } from "ts-optchain";
  import { mapState } from "vuex";
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import baseMap from '@/main/webapp/vue/components/map/base-map/index.vue';
  import PruneCluster from '@/main/webapp/vue/components/map/prune-cluster/index.vue';
  import markers from '@/main/webapp/vue/components/map/marker/index.vue';
  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';
  import ProjectPopup from '@/main/webapp/vue/components/map/marker/marker-popup/project-popup/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import mapConfiguration from '@/main/webapp/vue/components/map/mapConfiguration';
  import markerConfig from '@/main/webapp/vue/components/map/markerConfig';

  import { IconTypes } from "@/main/webapp/vue/model/api/Map/IconTypes";
  import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
  import { MapMarkerContainer } from "@/main/webapp/vue/model/api/Map/MapMarkerContainer";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { MapSubmissionContainer } from "@/main/webapp/vue/model/api/Map/MapSubmissionContainer";
  import { MapSubmission } from "@/main/webapp/vue/model/api/Map/MapSubmission";
  import { MapProjectDetails } from "@/main/webapp/vue/model/api/Map/MapProjectDetails";
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { MapProperties } from "@/main/webapp/vue/model/api/Map/MapProperties";

  export default Vue.extend({
    components: {
      baseMap,
      PruneCluster,
      markers,
      loadingIcon
    },
    data() {
      return {
        locations: [] as MapMarker[],
        nextPage: undefined as NavigationLink | undefined,
        mapObject: undefined as Map | undefined,
        mapHeight: `${window.innerHeight * 0.7}px` as string, // projectMap
        mapName: "project_map" as string,
        markerColors: mapConfiguration.config.markerColors as string[],
        viewInClusters: false as boolean,
        iconTypes: mapConfiguration.config.iconTypes as IconTypes,
        loading: false as boolean
      };
    },
    computed: {
      ...mapState([
        'mapProperties'
      ])
    },
    methods: {
      initLocations(): void {
        this.locations = [];
        this.nextPage = undefined;
      },
      viewWithoutCluster(): void {
        this.viewInClusters = false;
      },
      viewWithClusters(): void {
        this.viewInClusters = true;
      },
      fitBounds(): void {
        if (this.mapObject) {
          mapConfiguration.fitBounds(this.mapObject, this.locations);
        }
      },
      addMarkers(transformedMarkers: MapMarkerContainer, fitBounds: Boolean, projectId: number, projectStatuses: string): void {
        if (projectId === this.mapProperties.item.pid && this.mapProperties.item.ps === projectStatuses) { // Check fetched item is matched with current id
          if (transformedMarkers.markers) {
            this.locations.push.apply(this.locations, transformedMarkers.markers);

            if (this.locations.length > mapConfiguration.config.projectMapMarkerLimit) {
              this.viewWithClusters();
            } else {
              this.viewWithoutCluster();
            }
          }

          if (transformedMarkers.markerColors) {
            this.markerColors = transformedMarkers.markerColors;
          }

          if (fitBounds) {
            this.fitBounds();
          }
        }
      },
      getProjectLocations(projectId: number, projectStatuses: string): void {
        if (projectId === this.mapProperties.item.pid && this.mapProperties.item.ps === projectStatuses) { // Check fetched item is matched with current id
          this.loading = true;
          let parsedProjectStatuses: string = projectStatuses === "-1" ? `` : `ps=${projectStatuses}`;

          BackendIntegrationService.fetchMapProject(this.nextPage, projectId, parsedProjectStatuses).then((response: MapSubmissionContainer) => {
            if (response.list && response.list.length > 0) {
              let transformedMarkers: MapMarkerContainer = markerConfig.transformToMarkers(response.list, this.markerColors);
              if (transformedMarkers) {
                this.addMarkers(transformedMarkers, this.nextPage === undefined || this.locations.length === 0,
                                projectId, projectStatuses);
              }
            }

            let nav: NavigationLinks | undefined = Optional(response).nav();
            if (nav) {
              let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_NEXT);
              if (nextLink) {
                this.nextPage = nextLink;
                this.getProjectLocations(projectId, projectStatuses);
              } else {
                this.loadNoMore();
              }
            } else {
              this.loadNoMore();
            }
          }).catch((error: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Get submission locations failed', error);
            }
            notification.fail(this.$t('text.error.location'));
            this.loadNoMore();
          });
        }
      },
      loadNoMore(): void {
        this.nextPage = undefined;
        this.loading = false;
      },
      markerClicked(item: MapSubmission, leafletMarker: Marker): void {
        if (!this.viewInClusters && this.mapObject) { // If not viewInCluster, manually zoom
          this.mapObject.setView(leafletMarker.getLatLng(), mapConfiguration.config.defaultCloseZoom);
        }

        if (leafletMarker.getPopup() === undefined) {
          let nav: NavigationLinks | undefined = item.nav;
          if (nav) {
            let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_SELF)
              ? nav.getLink(NavigationLinkType.PAGE_SELF)
              : nav.getLink("shop");

            if (nextLink) {
              BackendIntegrationService.fetchMapShopDetails(nextLink, MapProjectDetails).then((response: MapProjectDetails) => {
                // Trick to use vue component html
                const projectPopupElement: string = new ProjectPopup({
                  propsData: {
                    item: response
                  }
                }).$mount().$el.outerHTML;

                leafletMarker.bindPopup(projectPopupElement, { minWidth: 200 });
                leafletMarker.openPopup();
              }).catch((e: Error) => {
                if (process.env.NODE_ENV !== 'production') {
                  console.log("Get details failed:", e);
                }
                notification.fail(this.$t('text.error.lookup'));
              });
            }
          }
        }
      },
      updateProjectMap(): void {
        let projectId: number | undefined = parseInt(
          this.$route.query.pid ? this.$route.query.pid : this.mapProperties.item.pid);
        let projectStatuses: string | undefined = this.mapProperties.item.ps;

        if (projectId && projectStatuses) {
          this.initLocations();
          this.getProjectLocations(projectId, projectStatuses);
        }
      },
      initMap(map: Map): void {
        this.mapObject = map;
        this.updateProjectMap();
      }
    },
    watch: {
      mapProperties(newValue: MapProperties, oldValue: MapProperties) {
        if (newValue === oldValue || (newValue.item.pid === oldValue.item.pid && newValue.item.ps === oldValue.item.ps) || newValue.item.pid === undefined) {
          return;
        }

        if (this.$route.hash.search(this.mapName) > -1) {
          this.updateProjectMap();
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
